import React from 'react';
import ReactDOM from 'react-dom/client';
import {createTheme, ThemeProvider} from "@mui/material";
import {Provider} from "react-redux";

import store from "./store/Store";
import App from './App';
import "./App.css"
import {BrowserRouter} from "react-router-dom";

export const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "black"
                }
            }
        }
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

