import React from "react";
import {Grid, Typography, Zoom} from "@mui/material";

import Books from "../../styles/images/photo-1521587760476-6c12a4b040da.jpg";

export function Imprint() {
    document.title = "MÜHLHANS - IMPRESSUM";
    return (
        <Zoom in>
            <Grid container direction="column" justifyContent="center" textAlign="center" spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h4">Impressum</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Hier finden Sie die gesetzlich vorgeschriebenen Angaben für unsere Seite.
                    </Typography>
                </Grid>
                <Grid item>
                    <img className="auto-resize mw-1400" src={Books} alt="Impressum" loading="lazy"/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Allgemeines</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Öffentlich bestellter Vermessungsingenieur (ÖbVI)
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Hammfelddamm 6
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        41460 Neuss
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Telefon: 02131 - 1709220
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Fax: 02131 - 1709226
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Email: post@vermessung-neuss.de
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Gesetzliche Berufsbezeichnungen</Typography>
                    <Typography variant="body1" color="text.secondary">
                        1) Öffentlich bestellter Vermessungsingenieur (nach ÖbVIG)
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        2) Beratender Ingenieur (nach BauKaG NW)
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        <b className="gray">verliehen in Deutschland durch</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 1): Der Innenminister des Landes Nordrhein-Westfalen, vertreten durch Bezirksregierung Düsseldorf, Dezernat 31
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 2): Ingenieurkammer Bau NW
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Zuständige Aufsicht</Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 1): Der Innenminister des Landes Nordrhein-Westfalen, vertreten durch Bezirksregierung Düsseldorf, Dezernat 31
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 2): Ingenieurkammer Bau NW
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Berufsordnungen</Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 1): Berufsrecht nach ÖbVI-Gesetz NRW
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        zu 2): Baukammerngesetz Nordrhein-Westfalen (BauKaG NW, zugänglich über recht.nrw.de)
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Steuer-Nr.</Typography>
                    <Typography variant="body1" color="text.secondary">
                        122/5347/5478
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Verantwortlich für die Inhalte</Typography>
                    <Typography variant="body1" color="text.secondary">
                        ÖbVI Dipl.-Ing. Andreas Mühlhans
                    </Typography>
                </Grid>
            </Grid>
        </Zoom>
    );
}